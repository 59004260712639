/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"2oGGLJS91RMU48s1hC4Nz"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";

Sentry.init({
  environment: getEnvironment(),
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: ["local"].includes(getEnvironment()) ? 1.0 : 0.1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: getEnvironment() !== "production",

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: ["local", "heroku"].includes(getEnvironment())
    ? 1.0
    : 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: getEnvironment() === "local",
      blockAllMedia: getEnvironment() === "local",
    }),
    Sentry.browserTracingIntegration(),
  ],
});

/**
 * We use the same built artifacts in every environment. Since client-side
 * environment variables get compiled in at build time, the only environment
 * variables that are available are the ones that are available on the build
 * server, which therefore do not necessarily represent the environment the code
 * will eventually run in. Therefore, we have to dynamically determine the
 * environment at runtime, which is what this function does.
 */
function getEnvironment() {
  if (
    document.location.origin === "https://monitor.firefox.com" ||
    document.location.origin === "https://monitor.mozilla.com" ||
    document.location.origin === "https://monitor.mozilla.org"
  ) {
    return "production";
  }
  if (
    document.location.origin ===
    "https://stage.firefoxmonitor.nonprod.cloudops.mozgcp.net"
  ) {
    return "stage";
  }
  if (document.location.origin === "https://fx-breach-alerts.herokuapp.com") {
    return "heroku";
  }
  if (document.location.hostname === "localhost") {
    return "local";
  }

  return "unknown";
}
